import axios from 'axios';

export default ({ requiresAuth = false } = {}) => {
    const options = {};

    options.baseURL = 'https://api-sanieren365.theonesystem.ch/api';
    // options.baseURL = 'http://127.0.0.1:8000/api';
    options.headers = {
        "Content-type": "application/json",
        "Accept": "application/json",
        // 'Authorization': `Bearer ${localStorage.token}`
    };

    if (requiresAuth) {
        options.headers.Authorization = `Bearer ${localStorage.token}`
    }
    const instance = axios.create(options);

    instance.interceptors.response.use(response => {
        return response;
    }, error => {
        return Promise.reject(error);
    });
    return instance;
};
