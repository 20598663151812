// import axios from "axios";
import api from "../../api"
export const state = {
    areInvoicesLoading: false,
    invoicesList: [],
    invoicesData: {},
    errorMsg: null,
    successMsg: null,

    isSearchingInvoices: false,
    searchInvoicesData: {},
}

export const mutations = {
    SET_LOADING(state, areInvoicesLoading) {
        state.areInvoicesLoading = areInvoicesLoading
    },
    SET_INVOICES_LIST(state, invoicesList) {
        state.invoicesList = invoicesList
    },
    SET_INVOICES_DATA(state, invoicesData) {
        state.invoicesData = invoicesData
    },
    SET_ERROR_MSG(state, errorMsg) {
        state.errorMsg = errorMsg
    },
    SET_SUCCESS_MSG(state, successMsg) {
        state.successMsg = successMsg
    },
    SET_IS_SEARCHING(state, isSearchingInvoices) {
        state.isSearchingInvoices = isSearchingInvoices;
    },

}

export const getters = {
    getInvoicesList: state => state.invoicesList,
    invoicesData: state => state.invoicesData,
    areInvoicesLoading: state => state.areInvoicesLoading,
    errorMsg: state => state.errorMsg,
    successMsg: state => state.successMsg,
    isSearchingInvoices: state => state.isSearchingInvoices,
    searchInvoicesData: state => state.searchInvoicesData,
}

export const actions = {
    async fetchInvoicesList({ commit }, page = 1) {
        await api({ requiresAuth: true }).get(`/invoices?page=${page}`)
            .then((result) => {
                const { data } = result;
                commit('SET_INVOICES_LIST', data)
            })
            .catch(error => {
                console.error(error)

            })
    },
    async getInvoicesData({ commit }, page = 1) {

        await api({ requiresAuth: true }).get(`/invoices?page=${page}`)
            .then((result) => {
                const { data } = result;
                commit('SET_INVOICES_DATA', data)
            })
            .catch(error => {
                console.error(error)

            })

    },
    async getSearchInvoicesData({ commit }, { page, searchDataObject }) {
        // commit('SET_LOADING', true);
        commit('SET_IS_SEARCHING', true);

        // commit('SET_SEARCH_DATA', searchDataObject);

        await api({ requiresAuth: true }).post(`/invoices/search?page=${page}`, searchDataObject)
            .then((response) => {
                const { data } = response;
                commit('SET_INVOICES_DATA', data);
                commit('SET_ERROR_MSG', null);
            })
            .catch((error) => {
                commit('SET_ERROR_MSG', error.response.data.message);
            })
            .finally(() => {
                commit('SET_LOADING', false);
            });
    },
}